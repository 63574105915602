import React from "react";
import "./App.css";
import Console1 from "./components/Console1/Console1";
import Console2 from "./components/Console2/Console2";

function App (){
  return(
    <div className="cointener">
      <Console1 />
      <Console2 />
    </div>
  )
}
export default App;